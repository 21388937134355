<template>
  <section class="selection-box">
    <div class="selection-box__row">
      <div class="selection-box__description">
        <slot />
      </div>
      <div
        v-if="$slots.controls"
        class="selection-box__controls"
      >
        <slot name="controls" />
        <div
          v-if="info"
          class="selection-box__info"
        >
          {{ info }}
        </div>
        <div class="selection-box__error">
          {{ error }}
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    error: {
      type: String,
      default: '',
    },
    info: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss">
.selection-box {
  padding: 10px 20px;

  &__row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
  }

  &__description {
    flex: 1 1 40%;
    max-width: 100%;
    line-height: 1.6;

    padding: 15px;
  }

  &__controls {
    flex: 1 1 60%;
    max-width: 100%;

    padding: 15px;

    @include media-bp(tab) {
      flex-basis: 100%;
    }
  }

  &__error,
  &__info {
    font-size: 12px;
    height: 1em;
  }

  &__error {
    color: $error-color;
  }

  a {
    color: $link-color;
    text-decoration-line: underline;
    font-weight: 700;
    border: none;
    background-color: none;
    cursor: pointer;
    padding: 0;
    text-align: start;

    &:hover {
      color: $link-color-hover;
    }
  }
}
</style>
