<template>
  <selection-box
    :error="error"
    :info="info"
  >
    Wählen Sie bitte die Termine aus, zu denen Sie werben wollen.<br>
    <a @click="toggleIsSelectedDecades">{{ toggleText }}</a>

    <template #controls>
      <app-select-daterange
        v-if="isDateRange"
        v-model="selectedDateRange"
        placeholder="Zeitraum auswählen"
      />
      <app-select-dwm
        v-else
        v-model="selectedDwm"
        :dwm-plan="actualDwmPlan"
      />
    </template>
  </selection-box>
</template>

<script>
import AppSelectDaterange from '@/components/app-select-daterange';
import AppSelectDwm from '@/components/app-select-dwm';
import SelectionBox from './selection-box';

export default {
  components: {
    AppSelectDaterange,
    AppSelectDwm,
    SelectionBox,
  },
  computed: {
    isDateRange: {
      get() { return this.$store.getters.currentCriteria.dwm.isDateRange; },
      set(value) {
        this.$store.dispatch('updateCriteria', { key: 'dwm.isDateRange', value });
        this.validate();
      },
    },
    selectedDateRange: {
      get() { return this.$store.getters.currentCriteria.dwm?.dateRange?.split('-'); },
      set(value) {
        this.$store.dispatch('updateCriteria', { key: 'dwm.dateRange', value: value.join('-') });
        this.validate();
      },
    },

    actualDwmPlan() {
      return this.$store.getters.actualDwmPlan;
    },
    selectedDwm: {
      get() {
        return this.$store.getters.currentCriteria.dwm?.dwmList || [];
      },
      set(value) {
        this.$store.dispatch('updateCriteria', { key: 'dwm.dwmList', value });
        this.validate();
      },
    },
    toggleText() {
      return this.isDateRange
        ? 'Ich möchte den Zeitraum lieber als Dekade auswählen'
        : 'Ich möchte lieber den Zeitraum frei auswählen';
    },
    error() {
      return this.$store.getters.criteriaErrors.dwm;
    },
    info() {
      const { minDays } = this.$store.getters.currentCriteria.dwm;
      return this.isDateRange && !this.selectedDateRange && !this.error && minDays
        ? `Bitte wählen Sie einen Zeitraum von mindenstens ${minDays} Tagen aus`
        : '';
    },
  },
  methods: {
    validate() {
      if (this.error) {
        this.$store.dispatch('validateCriteria', ['dwm']);
      }
    },
    toggleIsSelectedDecades() {
      this.isDateRange = !this.isDateRange;
    },
  },
};
</script>

<style lang="scss">

</style>
