<template>
  <div class="app-select-dwm">
    <div
      v-for="(dwmTypeNumbers, year) in dwmYearTypeNumbers"
      :key="year"
      class="app-select-dwm__year"
    >
      <div
        v-if="actualYears.length > 1"
        class="app-select-dwm__title"
      >
        {{ year }}:
      </div>
      <div class="app-select-dwm__content">
        <div
          v-for="(dwmNumbers, type) in dwmTypeNumbers"
          :key="type"
          class="app-select-dwm__type"
        >
          <div
            v-if="typesByYear(year).length > 1"
            class="app-select-dwm__title"
          >
            {{ type }}:
          </div>
          <div class="app-select-dwm__content">
            <app-dwm-checkbox
              v-for="(number, index) in dwmNumbers"
              :key="index"
              v-model="selectedDwmSets"
              :value="getDwmSet({ year, type, number })"
              :label="number"
              :info="getInfo({ year, type, number })"
              info-as-popup
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppDwmCheckbox from '@/components/app-dwm-checkbox';

export default {
  components: {
    AppDwmCheckbox,
  },
  props: {
    dwmPlan: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    currentDwmPlan() {
      return this.$store.getters.filterDwmPlanByType(this.dwmPlan, 'decade');
    },
    dwmYearTypeNumbers() {
      const result = {};
      /** @todo Currently only decade type to select, implement week and monat later */
      this.currentDwmPlan.forEach((dwm) => {
        const { year, type, number } = dwm;
        if (!result[year]) result[year] = {};
        if (!result[year][type]) result[year][type] = [];
        if (!result[year][type].includes(number)) result[year][type].push(number);

        result[year][type].sort();
      });
      return result;
    },
    actualYears() {
      return this.$store.getters.actualYears;
    },
    selectedDwmSets: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    typesByYear(year) {
      return Object.keys(this.dwmYearTypeNumbers[year]);
    },
    getDwmSet({ year, type, number }) {
      return this.selectedDwmSets
        .find((dwmSet) => dwmSet.year === +year && dwmSet.type === type && dwmSet.number === number)
        || { year, type, number };
    },
    getInfo({ year, type, number }) {
      const dwmSet = this.currentDwmPlan
        .filter((dwm) => dwm.year === +year && dwm.type === type && dwm.number === number);

      return dwmSet.map(this.$store.getters.getDwmInfo).join(`
      `);
    },
  },
};
</script>

<style lang="scss">
.app-select-dwm {

  &__year {
    margin-top: 2px;
    margin-bottom: 2px;
  }

  &__type {
    flex: 1 1 100%;
    & + & {
      margin-left: 4px;
    }
  }

  &__content {
    display: flex;
  }

  &__type &__content {
    flex-wrap: wrap;
  }

  .app-dwm-checkbox {
    $dwm: '.app-dwm-checkbox';
    margin: 2px;

    &__label {
      width: 30px;
      height: 30px;
      font-size: 20px;
      font-weight: 700;
      margin: 5px;

      color: $select-dwm-color;
      background-color: $select-dwm-bg-color;
      border: $select-dwm-border;
    }

    &:hover #{$dwm}__label {
      color: $select-dwm-color-hover;
      background-color: $select-dwm-bg-color-hover;
      border-color: $select-dwm-border-color-hover;
    }

    &__input:checked + #{$dwm}__label {
      color: $select-dwm-color-active;
      background-color: $select-dwm-bg-color-active;
      border-color: $select-dwm-border-color-active;
    }

    &:hover #{$dwm}__input:checked + #{$dwm}__label {
      color: $select-dwm-color-active-hover;
      background-color: $select-dwm-bg-color-active-hover;
      border-color: $select-dwm-border-color-active-hover;
    }

    &__info {
      #{$dwm}--infopopup & {
        // transform: translateY(-100%) translateY(-10px) translateX(-55%);

        &::after {

        }
      }
    }
  }
}
</style>
