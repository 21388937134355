<template>
  <selection-box :error="error">
    Bitte wählen Sie das Werbemedium aus, nach dem Sie suchen möchten.

    <template #controls>
      <app-select-media v-model="selectedMedia" />
    </template>
  </selection-box>
</template>

<script>
import AppSelectMedia from '@/components/app-select-media';
import SelectionBox from './selection-box';

export default {
  components: {
    AppSelectMedia,
    SelectionBox,
  },
  computed: {
    selectedMedia: {
      get() { return this.$store.getters.currentCriteria.media || []; },
      set(value) {
        this.$store.dispatch('updateCriteria', { key: 'media', value });
        if (this.error) {
          this.$store.dispatch('validateCriteria', ['media']);
        }
      },
    },
    error() {
      return this.$store.getters.criteriaErrors.media;
    },
  },
};
</script>

<style lang="scss">
</style>
