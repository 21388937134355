<template>
  <div class="criteria">
    <app-loader v-if="loader" />

    <media-selection-box class="criteria__selection-box" />
    <city-selection-box class="criteria__selection-box" />
    <dwm-selection-box class="criteria__selection-box" />

    <selection-box class="criteria__selection-box criteria__selection-box--button">
      <button
        class="btn btn--outfly criteria__button"
        @click="getMarkers"
      >
        ergebnisse anzeigen
      </button>
    </selection-box>
  </div>
</template>

<script>
import eventbus from '@/utils/eventbus';

import AppLoader from '@/components/app-loader';
import MediaSelectionBox from './components/media-selection-box';
import CitySelectionBox from './components/city-selection-box';
import DwmSelectionBox from './components/dwm-selection-box';
import SelectionBox from './components/selection-box';

import warningImages from './warning-images';

export default {
  components: {
    AppLoader,
    MediaSelectionBox,
    CitySelectionBox,
    DwmSelectionBox,
    SelectionBox,
  },
  data() {
    return {
      loader: false,
    };
  },
  computed: {
    currentErrors() {
      return Object.fromEntries(Object.entries(this.$store.getters.criteriaErrors).filter(([, error]) => error));
    },
    firstErrorKey() {
      return Object.keys(this.currentErrors)[0];
    },
    currentCriteriaQuery() {
      return this.$store.getters.currentCriteriaQuery;
    },
  },
  watch: {
    currentCriteriaQuery: {
      handler() {
        const query = this.currentCriteriaQuery;
        this.$router.replace({ ...this.$route, query })
          .catch((err) => (err.name === 'NavigationDuplicated' ? '' : Promise.reject(err)));
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    getMarkers() {
      this.$store.dispatch('validateCriteria');

      if (this.firstErrorKey) {
        this.showWarningMessage();
        return;
      }

      this.loader = true;
      this.$store.dispatch('loadMarkers').then(() => {
        this.$router.push({ name: 'result' });
      }).catch((err) => {
        console.error(err);

        this.loader = false;
      });
    },
    showWarningMessage() {
      const bodies = {
        // eslint-disable-next-line max-len
        media: 'Bitte wählen Sie das Werbemedium aus, nach dem Sie suchen möchten. Auch können Sie mehrere Medien gleichzeitig auswählen. Das ausgewählte Medium wird für Sie markiert dargestellt.',
        cities: `Bitte geben Sie den Namen des Ortes an, in dem Sie nach Standorten suchen wollen.

Wenn Sie sich in der Schreibweise des Ortes nicht ganz sicher sind, können Sie beliebige Teil des Ortsnamen schreiben.`,
        dwm: 'Wählen Sie bitte die VON und BIS, zu denen Sie werben wollen.',
      };

      eventbus.$emit('open-message', {
        body: bodies[this.firstErrorKey],
        image: warningImages[this.firstErrorKey],
      });
    },
  },
};
</script>

<style lang="scss">
.criteria {
  display: flex;
  flex-flow: column;

  overflow: auto;

  height: 100%;

  &__selection-box {
    flex: 1 0 auto;
    display: flex;
    flex-flow: column;
    justify-content: center;

    &:nth-child(even) {
      background-color: $section-gray-bg;
    }

    &--button {
      flex: 0 0 auto;
    }
  }

  &__button {
    width: 100%;
  }
}
</style>
