<template>
  <v-select
    v-model="selectedCity"
    :options="options"
    class="app-select-city"
    placeholder="Bitte einen Ort wählen"
    @search="onSearch"
  >
    <span slot="no-options">{{ noOptionText }}</span>
  </v-select>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      options: [],
      searchTerm: '',
    };
  },
  computed: {
    selectedCity: {
      get() { return this.value?.map(this.addLabel)[0]; },
      set(value) { this.$emit('input', value); },
    },
    minSearchCitySymbols() {
      return this.$store.state.city.minSearchCitySymbols;
    },
    noOptionText() {
      const symbolsCount = this.searchTerm.length;
      const minSymbolsCount = this.minSearchCitySymbols;
      if (symbolsCount) {
        return symbolsCount < minSymbolsCount
          ? `Bitte noch ${minSymbolsCount - symbolsCount} Symbole eintragen`
          : 'Es ist keine Variante gefunden';
      }
      return '';
      // return `Minimum ${minSymbolsCount} Symbole eintragen`;
    },
  },
  methods: {
    onSearch(term, loading) {
      this.searchTerm = term;
      if (term.length < this.minSearchCitySymbols) {
        this.options = [];
      } else {
        loading(true);
        this.$store.dispatch('searchCities', term).then((cities) => {
          this.options = cities.map(this.addLabel);
          loading(false);
        });
      }
    },
    addLabel(city) {
      return { ...city, label: `${city.ortbez}, ${city.plz}` };
    },
  },
};
</script>

<style lang="scss">
.app-select-city {
  @include v-select;

  margin-bottom: 12px;
}
</style>
