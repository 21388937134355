<template>
  <selection-box :error="error">
    Bitte geben Sie den Namen des Ortes an, in dem Sie nach Standorten suchen wollen.

    <template #controls>
      <app-select-city v-model="selectedCity" />
    </template>
  </selection-box>
</template>

<script>
import AppSelectCity from '@/components/app-select-city';
import SelectionBox from './selection-box';

export default {
  components: {
    AppSelectCity,
    SelectionBox,
  },
  computed: {
    selectedCity: {
      get() { return this.$store.getters.currentCriteria.cities; },
      set(city) {
        this.$store.dispatch('updateCriteria', { key: 'cities', value: (city && [city]) || [] });
        if (this.error) {
          this.$store.dispatch('validateCriteria', ['cities']);
        }
      },
    },
    error() {
      return this.$store.getters.criteriaErrors.cities;
    },
  },
};
</script>

<style lang="scss">
</style>
