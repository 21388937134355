<template>
  <div class="app-select-media">
    <label
      v-for="({ type, name, image, image_banner }) in mediaSet"
      :key="type"
      class="app-select-media__media"
      :class="{'app-select-media__media--active': isSelected(type)}"
    >
      <input
        v-model="selectedMedia"
        type="checkbox"
        :value="type"
      >
      <img
        :src="image_banner || image"
        class="app-select-media__media-image"
      >
      <span class="app-select-media__media-name">{{ name }}</span>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => {},
    },
  },
  computed: {
    selectedMedia: {
      get() { return this.value; },
      set(value) { this.$emit('input', value); },
    },
    mediaSet() {
      return this.$store.getters.mediaSet;
    },
  },
  methods: {
    isSelected(type) {
      return this.selectedMedia.includes(type);
    },
  },
};
</script>

<style lang="scss">
.app-select-media {
  display: flex;
  justify-content: space-between;

  @include media-bp(mob) {
    flex-wrap: wrap;
    justify-content: space-around;
  }

  &__media {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    height: 100px;
    padding: 5px 0;
    margin-bottom: 12px;
    font-size: 12px;
    text-align: center;
    opacity: 0.5;
    cursor: pointer;

    flex: 0 1 25%;
    max-width: 125px;

    color: $select-media-color;
    background-color: $select-media-bg-color;
    border: $select-media-border;

    &:hover {
      opacity: 1;
      color: $select-media-color-hover;
      background-color: $select-media-bg-color-hover;
      border-color: $select-media-border-color-hover;
    }

    &--active {
      opacity: 1;

      &,
      &:hover {
        color: $select-media-color-active;
        background-color: $select-media-bg-color-active;
        border-color: $select-media-border-color-active;
      }
    }

    input {
      display: none;
    }

    &:first-child {
      margin-left: 0;
    }

    @include media-bp(mob) {
      flex: 1 1 50%;
      max-width: calc(50% - 6px);
    }

    &-image {
      width: 90px;
      height: 70px;
    }

    &-name {
      padding-top: 7px;
    }
  }
}
</style>
